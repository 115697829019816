import React from "react";
import VideoDetail from "../components/VideoDetail";
import Sidebar from "../components/Sidebar";
import playlist from "../components/Playlist";
import { logout } from "../services/auth";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer, toast } from "react-toastify";
import api from "../services/api";

export default class HomePage extends React.Component {
  state = {
    isLoaded: false,
    videos: playlist,
    lastVideo: playlist[0].videos[0],
    selectedVideo: null
  };

  componentDidMount() {
    this.setSelectedVideo();
  }

  setSelectedVideo = async () => {
    await this.setLastVideo();
    const url = this.props.history.location.search;
    const query = new URLSearchParams(url);
    const urlVideoNumber = query.get("video");
    if (urlVideoNumber && urlVideoNumber <= 53) {
      let urlVideo = this.searchVideo(parseInt(urlVideoNumber));
      if (urlVideo) {
        this.setState({
          selectedVideo: urlVideo
        });
      }
    } else if (this.state.lastVideo.number > 1) {
      this.setState({
        selectedVideo: this.state.lastVideo
      });
    } else {
      this.setState({
        selectedVideo: playlist[0].videos[0]
      });
    }
  };

  setLastVideo = async () => {
    try {
      const response = await api.get("api/userinfo");
      if (response.status === 200) {
        let lastSeenNumber = response.data.lastSeen.number;
        if (lastSeenNumber) {
          let lastSeenVideo = this.searchVideo(lastSeenNumber);
          if (lastSeenVideo) {
            this.setState({
              lastVideo: lastSeenVideo
            });
          }
        }
      }
    } catch (err) {
      toast.error(err);
    }
  };

  searchVideo = videoNumber => {
    let filteredVideos = this.state.videos.map(modulo => {
      return modulo.videos.filter(video => video.number === videoNumber);
    });
    for (const item of filteredVideos) {
      if (item[0]) {
        let wantedVideo = item[0];
        return wantedVideo;
      }
    }
  };

  saveLastVideo = async video => {
    if (video.number > this.state.lastVideo.number) {
      this.setState({ lastVideo: video });
      try {
        const response = await api.put("api/user", {
          lastSeen: {
            number: video.number,
            title: video.title
          }
        });
        console.log("check last video: ", response.status);
      } catch (err) {
        toast.error(err);
      }
    }
  };

  changeIsLoaded = () => {
    this.setState({
      isLoaded: true
    });
  };

  play = async video => {
    await this.setState({
      selectedVideo: video,
      isLoaded: false
    });
    this.saveLastVideo(video);
  };

  sair() {
    logout();
    window.location.href = "/";
  }

  render() {
    return (
      <div className="wrapper">
        <ToastContainer />
        <VideoDetail
          video={this.state.selectedVideo}
          sair={this.sair}
          play={this.play}
          changeIsLoaded={this.changeIsLoaded}
        />
        <Sidebar
          videos={this.state.videos}
          play={this.play}
          lastVideoNum={this.state.lastVideo.number}
          isLoaded={this.state.isLoaded}
        />
      </div>
    );
  }
}
