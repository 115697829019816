import React from "react";

const EnxugarGastos = () => {
  return (
    <div className="row">
      <div className="col-md-12 text-center">
        <h3>Enxugar Gastos</h3>
        <a
          href="https://app.ondazul.online/pq5+1"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary text-center"
        >
          <strong style={{ textTransform: "none", fontSize: "14px" }}>
            Ir para Ondazul
          </strong>
        </a>
      </div>
    </div>
  );
};

export default EnxugarGastos;
