import React from "react";

const VisaoUmAno = () => {
  return (
    <div className="row">
      <div className="col-md-12 text-center">
        <h3>Criar painel 1 ano</h3>

        <a
          href="https://app.ondazul.online/V1Ano"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-primary text-center"
        >
          <strong style={{ textTransform: "none", fontSize: "14px" }}>
            Ir para Ondazul
          </strong>
        </a>
      </div>
    </div>
  );
};

export default VisaoUmAno;
