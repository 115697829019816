import React from "react";

const ActivityRenderer = ({ activity }) => {
  if (!activity) {
    return <></>;
  }
  return (
    <div className="card">
      <div className="card-header card-header-info card-header-icon">
        <div className="card-icon">
          <i className="material-icons">edit</i>
        </div>
      </div>
      <div className="card-body">{activity}</div>
    </div>
  );
};

export default ActivityRenderer;
