import React from "react";
import api from "../services/api";
import Select from "react-select";
import { toast } from "react-toastify";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";

export default class AgendarReuniao extends React.Component {
  state = {
    calendarRef: React.createRef(),
    horarios: [],
    calendarEvents: [],
    selectedEvent: null,
    selectedTime: null
  };

  // -------------- React Functions --------------

  componentDidMount() {
    this.createCalendarEvents();
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-md-12 text-center">
            <h3>Agendar Reunião de Mapeamento</h3>
          </div>
          <div className="col-md-12 text-center">
            <button
              id="toggleBtn"
              className="btn btn-primary"
              type="button"
              data-toggle="collapse"
              data-target="#collapseExample"
              aria-expanded="false"
              aria-controls="collapseExample"
              onClick={this.showCalendar}
            >
              <strong style={{ textTransform: "none", fontSize: "14px" }}>
                Clique para escolher um horário
              </strong>
            </button>
          </div>
        </div>
        <div id="collapseExample" className="collapse row text-center">
          <div className="col-md-8 ml-auto mr-auto">
            <br />
            <br />
            <FullCalendar
              ref={this.state.calendarRef}
              defaultView="dayGridMonth"
              views={{
                dayGridMonth: { buttonText: "Calendário" },
                listMonth: { buttonText: "Lista" }
              }}
              header={{
                left: "prev, next",
                center: "title",
                right: "dayGridMonth, listMonth"
              }}
              locale={"pt-br"}
              validRange={{
                start: new Date()
              }}
              plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
              weekends={false}
              events={this.state.calendarEvents}
              timeZone={"America/New_York"}
              dateClick={this.handleDateClick}
              eventClick={this.handleEventClick}
            />
            <br />
            <div className="modal" id="modalMarcarReuniao" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      id="closeModalMarcarReuniao"
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title">
                      Escolha um horário disponível
                    </h4>
                  </div>
                  <div className="modal-body">
                    <Select
                      id="selectMenu"
                      value={this.state.selectedTime}
                      onChange={this.changeSelectedTime}
                      options={this.state.horarios}
                      isOptionDisabled={option => option.disabled === true}
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      id="btnMarcarReuniao"
                      type="submit"
                      className="btn btn-success"
                      onClick={this.createNewEvent}
                    >
                      Confirmar
                    </button>
                    <button
                      id="btnCancelarMarcarReuniao"
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal" id="modalExcluirReuniao" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      id="closeModalExcluirReuniao"
                      type="button"
                      className="close"
                      data-dismiss="modal"
                    >
                      &times;
                    </button>
                    <h4 className="modal-title">Desmarcar reunião</h4>
                  </div>
                  <div className="modal-body">
                    <p>Deseja realmente desmarcar esta reunião?</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      id="btnExcluirReuniao"
                      type="submit"
                      className="btn btn-danger"
                      onClick={this.deleteEvent}
                    >
                      Excluir
                    </button>
                    <button
                      id="btnCancelarExcluirReuniao"
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  // -------------- Handle Functions --------------

  handleDateClick = async arg => {
    let modal = document.getElementById("modalMarcarReuniao");
    modal.style.display = "block";

    let close = document.getElementById("closeModalMarcarReuniao");
    close.onclick = function() {
      modal.style.display = "none";
    };

    let cancel = document.getElementById("btnCancelarMarcarReuniao");
    cancel.onclick = function() {
      modal.style.display = "none";
    };

    window.onclick = function(event) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };

    const dataEvento = new Date(arg.date);
    if (!dataEvento) toast.error("Erro: selecione uma data");
    else {
      try {
        const response = await api.post("api/appointments_day", {
          date: dataEvento
        });
        var listaHorarios = response.data.map(horario => {
          return {
            label: horario.time,
            value: horario.value,
            assistents: horario.availableAssistents,
            disabled: !horario.available
          };
        });
        this.setState({
          horarios: listaHorarios
        });
      } catch {
        toast.error("Erro ao consultar horários disponíveis");
      }
    }
  };

  handleEventClick = arg => {
    this.changeSelectedEvent(arg.event);

    let modal = document.getElementById("modalExcluirReuniao");
    modal.style.display = "block";

    let close = document.getElementById("closeModalExcluirReuniao");
    close.onclick = function() {
      modal.style.display = "none";
    };

    let cancel = document.getElementById("btnCancelarExcluirReuniao");
    cancel.onclick = function() {
      modal.style.display = "none";
    };

    window.onclick = function(event) {
      if (event.target === modal) {
        modal.style.display = "none";
      }
    };
  };

  // -------------- AgendarReuniao Functions --------------

  showCalendar = calendar => {
    let calendarEl = document.getElementsByClassName("fc-scroller");
    calendarEl[0].setAttribute("style", "overflow: visible");
  };

  createCalendarEvents = async calendar => {
    let today = new Date();
    let newDate = new Date();
    newDate.setMonth(today.getMonth() + 6);

    let startDate = today;
    let endDate = newDate;

    try {
      const response = await api.get(
        "api/appointment?start=" + startDate + "&end=" + endDate
      );
      var listaEventos = response.data.map(evento => {
        return {
          id: evento._id,
          title: evento.name,
          start: evento.date
        };
      });
      this.setState({
        calendarEvents: listaEventos
      });
    } catch {
      toast.error("Erro ao mostrar eventos");
    }
  };

  changeSelectedTime = selectedTime => {
    this.setState({ selectedTime });
  };

  changeSelectedEvent = selectedEvent => {
    this.setState({ selectedEvent });
  };

  // -------------- CRUD Functions --------------

  createNewEvent = async e => {
    if (!this.state.selectedTime) {
      toast.error(
        "Erro ao salvar evento: você deve escolher um horário para a reunião"
      );
    } else {
      let dataReuniao = this.state.selectedTime.value;
      let index = Math.floor(
        Math.random() * this.state.selectedTime.assistents.length
      );
      let assistentId = this.state.selectedTime.assistents[parseInt(index)];
      try {
        const response = await api.post("api/appointment/" + assistentId, {
          date: dataReuniao,
          name: "Mapear Orçamento"
        });
        if (response.status === 200) {
          this.setState({
            calendarEvents: this.state.calendarEvents.concat({
              id: response.data._id,
              title: response.data.name,
              start: response.data.date
            })
          });
          toast.success("Reunião agendada com sucesso");
        }
      } catch (err) {
        toast.error(err.response.data.error || "Internal Server Error");
        // toast.error(
        //   "Erro ao salvar evento: verifique se há outra reunião agendada"
        // );
        toast.warning(
          "Caso deseje alterar a data ou o horário da sua reunião, remova a reunião anterior e marque uma nova reunião na data desejada"
        );
      }
    }
    let modal = document.getElementById("modalMarcarReuniao");
    modal.style.display = "none";
  };

  deleteEvent = async e => {
    if (!this.state.selectedEvent) {
      toast.error("Erro: você deve escolher uma reunião");
    } else {
      try {
        const response = await api.delete(
          "api/appointment/" + this.state.selectedEvent.id
        );
        if (response.status === 200) {
          this.setState({
            calendarEvents: this.state.calendarEvents.filter(evento => {
              return evento.id !== this.state.selectedEvent.id;
            })
          });
          toast.success("Reunião excluída com sucesso");
        }
      } catch {
        toast.error("Erro ao excluir reunião");
      }
    }
    let modal = document.getElementById("modalExcluirReuniao");
    modal.style.display = "none";
  };
}
