import React, { useState, useEffect } from "react";
import Quiz from "./Quiz";
import "./index.css";
import questions from "./QuestoesCrencas";
import api from "../../services/api";
import StartQuizOptions from "./StartQuizOptions";

export default function Crencas() {
  const [crencasState, setCrencasState] = useState({
    answers: [],
    current: 0,
    questions,
    total: questions.length,
    ambiente: 0,
    causaEfeito: 0,
    capacidade: 0,
    valor: 0,
    identidade: 0,
    pertencimento: 0,
    espiritualidade: 0,
    done: false,
    coupleDone: false,
    crencas: {},
    doing: false,
    name: null
  });

  useEffect(() => {
    async function fetchCrenca() {
      const { data: crenca } = await api.get("api/crenca");
      if (crenca) {
        setCrencasState(crencasState => ({
          ...crencasState,
          answers: crenca,
          done: !!crenca.length,
          coupleDone: !!crenca[1]
        }));
      }
    }
    fetchCrenca();
  }, []);

  return (
    <>
      {!crencasState.doing && (
        <StartQuizOptions
          crencasState={crencasState}
          setCrencasState={setCrencasState}
        />
      )}
      {crencasState.doing && (
        <Quiz crencas={crencasState} setCrencasState={setCrencasState} />
      )}
    </>
  );
}
