import React, { useState } from "react";
import PropTypes from "prop-types";
import Option from "./Option";

export default function Quiz({ mmd, setMmdState }) {
  const { questoes } = mmd;
  const percent = (mmd.current / mmd.total) * 100;
  const [currentName, setCurrentName] = useState("");
  return (
    <>
      <div className="row">
        <div className="col-md-9 ml-auto mr-auto">
          <div className="questionCount text-center">
            {Math.round(percent)}% Respondido
          </div>
          {mmd.name ? (
            <>
              <h3 className="question text-center">
                {questoes[mmd.current].pergunta}
              </h3>
              <ul>
                <Option
                  setMmdState={setMmdState}
                  mmd={mmd}
                  name={mmd.name}
                  peso={0}
                  content="Nunca"
                />
                <Option
                  setMmdState={setMmdState}
                  mmd={mmd}
                  name={mmd.name}
                  peso={0.25}
                  content="Raramente"
                />
                <Option
                  setMmdState={setMmdState}
                  mmd={mmd}
                  name={mmd.name}
                  peso={0.5}
                  content="Às vezes"
                />
                <Option
                  setMmdState={setMmdState}
                  mmd={mmd}
                  name={mmd.name}
                  peso={0.75}
                  content="Frequentemente"
                />
                <Option
                  setMmdState={setMmdState}
                  mmd={mmd}
                  name={mmd.name}
                  peso={1}
                  content="Sempre"
                />
              </ul>
            </>
          ) : (
            <>
              <div>
                <h3 className="question text-center">Insira seu Nome</h3>
                <input
                  type="text"
                  value={currentName}
                  className="form-control"
                  onChange={e => setCurrentName(e.target.value)}
                  placeholder="Insira seu E-mail aqui..."
                />
                <br />
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    setMmdState(c => ({ ...c, name: currentName }))
                  }
                >
                  <strong style={{ textTransform: "none", fontSize: "14px" }}>
                    Salvar
                  </strong>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

Quiz.propTypes = {
  mmd: PropTypes.shape().isRequired,
  setMmdState: PropTypes.func.isRequired
};
