import React from "react";

const VideoItem = ({ modulo, play, lastVideoNum, isLoaded }) => {
  return modulo.videos.map(video => {
    return (
      <li key={video.idVideo} className="nav-item">
        <a href="#" className="nav-link" onClick={() => play(video)}>
          {video.title}
        </a>
      </li>
    );
  });
};

export default VideoItem;

// {isLoaded && video.number < lastVideoNum + 2 ? (
//   <a href="#" className="nav-link" onClick={() => play(video)}>
//     {video.title}
//   </a>
// ) : (
//   <a href="#" className="nav-link" style={{ color: "gray" }}>
//     {video.title}
//   </a>
// )}
