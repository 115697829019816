import React from "react";
import Playlist from "../components/Playlist";

const video = Playlist[2].videos[2];

const ComoRessignificar = ({ play }) => {
  return (
    <div className="row">
      <div className="col-md-12 text-center">
        <h3>Como ressignificar suas crenças</h3>
        <button className="btn btn-primary" onClick={() => play(video)}>
          <strong style={{ textTransform: "none", fontSize: "14px" }}>
            Clique e descubra
          </strong>
        </button>
      </div>
    </div>
  );
};

export default ComoRessignificar;
