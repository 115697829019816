import React from "react";
import questoes from "./QuestoesMMD";

export default function StartQuizOptions({ mmdState, setMmdState }) {
  return (
    <>
      <div className="row">
        <div className="col-md-12 text-center">
          <h3>Teste MMD - Modelo Mental sobre Dinheiro</h3>
        </div>
        <div className="col-md-9 ml-auto mr-auto text-center">
          {!mmdState.done ? (
            <button
              onClick={() =>
                setMmdState(c => ({
                  ...c,
                  doing: true,
                  done: false,
                  current: 0,
                  questoes,
                  total: questoes.length,
                  esperanca: 0,
                  reatividade: 0,
                  inseguranca: 0,
                  carencia: 0,
                  insatisfacao: 0,
                  identificacao: 0,
                  negligencia: 0,
                  impulsividade: 0,
                  otimismo: 0,
                  name: null
                }))
              }
              className="btn btn-primary"
            >
              <strong style={{ textTransform: "none", fontSize: "14px" }}>
                Iniciar teste
              </strong>
            </button>
          ) : (
            <a
              href="https://app.ondazul.online/comportamento"
              target="_blank"
              rel="noopener noreferrer"
              class="btn btn-primary"
            >
              <strong style={{ textTransform: "none", fontSize: "14px" }}>
                Concluir e ver resultado
              </strong>
            </a>
          )}
          {mmdState.done ? (
            <>
              {mmdState.coupleDone ? (
                <a
                  href="https://app.ondazul.online/comportamento"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-primary btn-lg"
                >
                  <strong>Concluir e ver resultado do cônjuge</strong>
                </a>
              ) : (
                <button
                  onClick={() =>
                    setMmdState(c => ({
                      ...c,
                      done: false,
                      doing: true,
                      current: 0,
                      questoes,
                      total: questoes.length,
                      esperanca: 0,
                      reatividade: 0,
                      inseguranca: 0,
                      carencia: 0,
                      insatisfacao: 0,
                      identificacao: 0,
                      negligencia: 0,
                      impulsividade: 0,
                      otimismo: 0,
                      name: null
                    }))
                  }
                  className="btn btn-primary"
                >
                  <strong style={{ textTransform: "none", fontSize: "14px" }}>
                    Iniciar teste do cônjuge
                  </strong>
                </button>
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
