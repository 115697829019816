import React from "react";
import shortid from "shortid";

import AcoesTrintaDias from "../activities/AcoesTrintaDias";
import SemanaDecisiva from "../activities/SemanaDecisiva";
import DiaD from "../activities/DiaD";
import AgendarReuniao from "../activities/AgendarReuniao";
import AlinharRAC from "../activities/AlinharRAC";
import EnxugarGastos from "../activities/EnxugarGastos";
import FormularioCrencas from "../activities/FormularioCrencas";
import FormularioMMD from "../activities/FormularioMMD";
import RessignificarCrencas from "../activities/RessignificarCrencas";
import VisaoCincoAnos from "../activities/VisaoCincoAnos";
import VisaoUmAno from "../activities/VisaoUmAno";
 
const playlist = [
  {
    nomeModulo: "Módulo 0: Descobrindo a viagem",
    idModulo: shortid.generate(),
    videos: [
      {
        number: 1,
        title: "0.0 - Apresentação",
        url: "https://vimeo.com/357608318/39f1673487",
        activity: null
      },
      {
        number: 2,
        title: "0.1 - Eu já fui endividado",
        url: "https://vimeo.com/357430559/0fecfabbcf",
        activity: null
      },
      {
        number: 3,
        title: "0.2 - Gerar X Gerir",
        url: "https://vimeo.com/357431017/e5693cfcdb",
        activity: null
      },
      {
        number: 4,
        title: "0.3 - O coqueiro e seu Modelo Mental sobre Dinheiro",
        url: "https://vimeo.com/357431475/4c9bc96a1f",
        activity: null
      },
      {
        number: 5,
        title: "0.4 - Autoresponsabilidade",
        url: "https://vimeo.com/357431873/ba14ed6c59",
        activity: null
      },
      {
        number: 6,
        title: "0.5 - Padrão de linguagem",
        url: "https://vimeo.com/357432644/6e6b6a4603",
        activity: null
      },
      {
        number: 7,
        title: "0.6 - Método + Sistema + Assistente = ONDAZUL",
        url: "https://vimeo.com/357433228/a00d3d3031",
        activity: null
      },
      {
        number: 8,
        title: "0.7 - Permaneça na zona de conforto",
        url: "https://vimeo.com/357433881/b9188cd0c4",
        activity: null
      },
      // {
      //   number: 9,
      //   title: "0.8 - Bora começar",
      //   url: "https://vimeo.com/357434264/d3c65efa1d",
      //   activity: <AgendarReuniao />
      // }
    ]
  },
  {
    nomeModulo: "Módulo 1: Mapear",
    idModulo: shortid.generate(),
    videos: [
      {
        number: 10,
        title: "1.0 - Estado atual",
        url: "https://vimeo.com/357609137/6cf6dd7b05",
        activity: null
      },
      {
        number: 11,
        title: "1.1 - Painel de controle: como funciona",
        url: "https://vimeo.com/357624544/9d45a52b56",
        activity: null
      },
      {
        number: 12,
        title: "1.2 - E o cartão de crédito?",
        url: "https://vimeo.com/357638832/645200f4a2",
        activity: null
      },
      {
        number: 13,
        title: "1.3 - Hora de mapear as dívidas",
        url: "https://vimeo.com/357624163/b580174bb0",
        activity: null
      },
      {
        number: 14,
        title: "1.4 - Como cadastrar ativos na Ondazul",
        url: "https://vimeo.com/357625814/9923628cf2",
        activity: null
      },
      {
        number: 15,
        title: "1.5 - Como cadastrar passivos na Ondazul",
        url: "https://vimeo.com/357625889/655e209abe",
        activity: null
      },
      {
        number: 16,
        title: "1.6 - Tipos de pote: orçamentos",
        url: "https://vimeo.com/357639288/e089b3902c",
        activity: null
      },
      {
        number: 17,
        title: "1.7 - Hora de descobrir o seu MMD",
        url: "https://vimeo.com/357627499/7a9e5077c5",
        activity: <FormularioMMD />
      },
      {
        number: 18,
        title: "1.8 - Dívidas Conjunturais",
        url: "https://vimeo.com/357627972/d3d442430d",
        activity: null
      },
      {
        number: 19,
        title: "1.9 - Dívidas Estruturais",
        url: "https://vimeo.com/357628690/1a77c3a129",
        activity: null
      },
      {
        number: 20,
        title: "1.10 - Dívidas de Oportunidade",
        url: "https://vimeo.com/357638084/e872bc1495",
        activity: null
      },
      {
        number: 21,
        title: "1.11 - Descobrindo as suas raízes: as Crenças",
        url: "https://vimeo.com/357642447/5ceeb670f6",
        activity: <FormularioCrencas />
      },
      {
        number: 22,
        title: "1.12 - Entendendo os tipos de Crenças sobre dinheiro",
        url: "https://vimeo.com/357642677/46ab08acd8",
        activity: null
      }
    ]
  },
  {
    nomeModulo: "Módulo 2: Almejar",
    idModulo: shortid.generate(),
    videos: [
      {
        number: 23,
        title: "2.0 - Estado ideal",
        url: "https://vimeo.com/357628374/ed4f63a1fd",
        activity: null
      },
      {
        number: 24,
        title: "2.1 - Ressignificação de crenças",
        url: "https://vimeo.com/357628536/a7b7ff10cb",
        activity: null
      },
      {
        number: 25,
        title: "2.2 - Como ressignificar crenças no Ondazul",
        url: "https://vimeo.com/357636972/7df54230ec",
        activity: <RessignificarCrencas />
      },
      {
        number: 26,
        title: "2.3 - Seu futuro: visão de 5 anos",
        url: "https://vimeo.com/357638333/3c6f1d7441",
        activity: null
      },
      {
        number: 27,
        title: "2.4 - Criando seu painel de 5 anos",
        url: "https://vimeo.com/357638951/a613433dfc",
        activity: <VisaoCincoAnos />
      },
      {
        number: 28,
        title: "2.5 - Seu futuro: visão de 1 ano",
        url: "https://vimeo.com/357641433/4cd232c04c",
        activity: null
      },
      {
        number: 29,
        title: "2.6 - Criando seu painel de 1 ano",
        url: "https://vimeo.com/357642950/663784e45e",
        activity: <VisaoUmAno />
      },
      {
        number: 30,
        title: "2.7 - Um norte para seguir",
        url: "https://vimeo.com/357644151/6abcf5a9ac",
        activity: null
      }
    ]
  },
  {
    nomeModulo: "Módulo 3: Planejar",
    idModulo: shortid.generate(),
    videos: [
      {
        number: 31,
        title: "3.0 - Definindo o caminho: do Atual para o Ideal",
        url: "https://vimeo.com/357644966/92751d207e",
        activity: null
      },
      {
        number: 32,
        title: "3.1 - RAC: Resultado, Ação e Crença alinhados",
        url: "https://vimeo.com/357646886/f8ed25b729",
        activity: null
      },
      {
        number: 33,
        title: "3.2 - Como alinhar seu RAC",
        url: "https://vimeo.com/357647704/92bc0a4e9c",
        activity: <AlinharRAC />
      },
      {
        number: 34,
        title: "3.3 - PQ5+1: Enxugar",
        url: "https://vimeo.com/357649181/5d6becc217",
        activity: null
      },
      {
        number: 35,
        title: "3.4 - Quais estratégias utilizar",
        url: "https://vimeo.com/357650124/37275f4e0e",
        activity: null
      },
      {
        number: 36,
        title: "3.5 - Como enxugar seus gastos?",
        url: "https://vimeo.com/357650124/37275f4e0e",
        activity: <EnxugarGastos />
      },
      {
        number: 37,
        title: "3.6 - PQ5+1: Recebimento Extra",
        url: "https://vimeo.com/357653714/1d22ca5336",
        activity: null
      },
      {
        number: 38,
        title: "3.7 - Utilizando o Recebimento Extra",
        url: "https://vimeo.com/357657181/a8f54db59f",
        activity: null
      },
      {
        number: 39,
        title: "3.8 - PQ5+1: Empréstimo familiar",
        url: "https://vimeo.com/357653352/47975d4cc0",
        activity: null
      },
      {
        number: 40,
        title: "3.9 - Empréstimo entre amigos e parentes",
        url: "https://vimeo.com/357652591/58edd6c1f6",
        activity: null
      },
      {
        number: 41,
        title: "3.10 - PQ5+1: Consolidação",
        url: "https://vimeo.com/357652766/599eeffe4d",
        activity: null
      },
      {
        number: 42,
        title: "3.11 - Como consolidar suas dívidas",
        url: "https://vimeo.com/357652206/985bf5ee82",
        activity: null
      },
      {
        number: 43,
        title: "3.12 - PQ5+1: Liquidação patrimonial",
        url: "https://vimeo.com/357651572/b7cfb46dad",
        activity: null
      },
      {
        number: 44,
        title: "3.13 - Cadastrar a Liquidação Patrimonial (Imóveis)",
        url: "https://vimeo.com/357651141/2250925305",
        activity: null
      },
      {
        number: 45,
        title: "3.14 - Cadastrar a Liquidação Patrimonial (Veículos)",
        url: "https://vimeo.com/357651241/1ad2417937",
        activity: null
      },
      {
        number: 46,
        title: "3.15 - Comprar X Alugar",
        url: "https://vimeo.com/357650516/7e8bbe9ff4",
        activity: null
      },
      {
        number: 47,
        title: "3.16 - PQ5+1: Recuperação financeira",
        url: "https://vimeo.com/357649247/9474b2ebc6",
        activity: null
      }
    ]
  },
  {
    nomeModulo: "Módulo 4: Atuar",
    idModulo: shortid.generate(),
    videos: [
      {
        number: 48,
        title: "4.0 - Hora de agir",
        url: "https://vimeo.com/357645452/21840fcff3",
        activity: null
      },
      {
        number: 49,
        title: "4.1 - Ações 30D",
        url: "https://vimeo.com/357645741/db286b5978",
        activity: <AcoesTrintaDias />
      },
      {
        number: 50,
        title: "4.2 - A semana decisiva",
        url: "https://vimeo.com/357646591/1e8ecd4e77",
        activity: <SemanaDecisiva />
      },
      {
        number: 51,
        title: "4.3 - O Dia D",
        url: "https://vimeo.com/357647162/2e29781d25",
        activity: <DiaD />
      },
      {
        number: 52,
        title: "4.4 - As chances a seu favor",
        url: "https://vimeo.com/357647723/8de72bbdac",
        activity: null
      },
      {
        number: 53,
        title: "4.5 - Agora é com você!",
        url: "https://vimeo.com/357648050/529a91644d",
        activity: null
      }
    ]
  }
];

playlist.map(modulo => {
  return modulo.videos.map(video => {
    return (video.idVideo = shortid.generate());
  });
});

export default playlist;
