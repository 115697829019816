import React from "react";
import api from "../services/api";
import moment from "moment";
import shortid from "shortid";
import Countdown from "react-countdown-now";
import { toast } from "react-toastify";

export default class AcoesTrintaDias extends React.Component {
  state = {
    date: Date.now() + 240000,
    countdownApi: Countdown.CountdownApi,
    isDone: false,
    actionsList: [],
    action: ""
  };

  // --------------- React Functions ----------------

  componentDidMount() {
    this.getUserActions();
  }

  setCountdownRef = countdown => {
    if (countdown) {
      this.countdownApi = countdown.getApi();
    }
  };

  // --------------- Handle Functions ----------------

  handleTextChange = e => {
    this.setState({
      action: e.target.value
    });
  };

  handleFormSubmit = async el => {
    try {
      if (this.state.action) {
        let defaultDate = moment()
          .add(this.props.actionType, "days")
          .endOf("day")
          .format();
        const response = await api.post("api/a30d", {
          acao: this.state.action,
          quando: defaultDate
        });
        if (response.status === 200) {
          this.setState({
            actionsList: [this.state.action, ...this.state.actionsList],
            action: ""
          });
        }
      }
    } catch {
      toast.error("Erro: digite uma ação");
    }
  };

  handleStartClick = () => {
    this.countdownApi && this.countdownApi.start();
    let btnStartCountdown = document.getElementById("btnStartCountdown");
    btnStartCountdown.disabled = true;
    let inputAction = document.getElementById("inputAction");
    inputAction.disabled = false;
  };

  handleCountdownComplete = () => {
    let inputAction = document.getElementById("inputAction");
    inputAction.disabled = true;
  };

  // --------------- AcoesTrintaDias Functions ----------------

  formatNumbers = num => {
    let formatedNum = num.toLocaleString("pt-BR", {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
    return formatedNum;
  };

  getDifference = action => {
    let start = moment(action.createdAt).startOf("day");
    let end = moment(action.quando).endOf("day");
    let difference = end.diff(start, "days");
    return difference;
  };

  async getUserActions() {
    try {
      const response = await api.get("api/a30d");
      if (response.status === 200 && response.data.length > 0) {
        let filteredActions = response.data
          .map(action => {
            return {
              acao: action.acao,
              difference: this.getDifference(action)
            };
          })
          .filter(action => action.difference <= this.props.actionType);

        if (filteredActions.length > 0) {
          let userActions = filteredActions.map(action => {
            return action.acao;
          });
          this.setState({
            actionsList: userActions,
            isDone: true
          });
        }
      }
    } catch {
      toast.error("Erro ao buscar ações");
    }
  }

  // ------------------- Countdown Function ------------------

  renderer = counter => {
    if (counter.completed) {
      return (
        <div className="text-center">
          <h1>Tempo esgotado</h1>;
          <a
            href={
              "https://app.ondazul.online/acoes_atuar" +
              this.props.actionType +
              "d"
            }
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
          >
            <strong style={{ textTransform: "none", fontSize: "14px" }}>
              Ir para Ondazul
            </strong>
          </a>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <h1>
            {this.formatNumbers(counter.minutes)}:
            {this.formatNumbers(counter.seconds)}
          </h1>
          <p />
        </div>
      );
    }
  };

  render() {
    return (
      <div className="row">
        {this.state.isDone ? (
          <div className="col-md-8 ml-auto mr-auto text-center">
            <h3>Minhas Ações</h3>
            <a
              href={
                "https://app.ondazul.online/acoes_atuar" +
                this.props.actionType +
                "d"
              }
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              <strong style={{ textTransform: "none", fontSize: "14px" }}>
                Ir para Ondazul
              </strong>
            </a>
          </div>
        ) : (
          <div className="col-md-8 ml-auto mr-auto">
            <Countdown
              ref={this.setCountdownRef}
              date={this.state.date}
              renderer={this.renderer}
              autoStart={false}
              onComplete={this.handleCountdownComplete}
            />
            <div className="text-center">
              <button
                id="btnStartCountdown"
                type="button"
                className="btn btn-primary"
                onClick={this.handleStartClick}
              >
                <strong style={{ textTransform: "none", fontSize: "14px" }}>
                  Começar
                </strong>
              </button>
            </div>
            <div className="col-md-12">
              <form onSubmit={this.handleFormSubmit}>
                <div className="form-group bmd-form-group">
                  <label className="bmd-label-floating">Digite uma ação</label>
                  <input
                    id="inputAction"
                    type="text"
                    className="form-control"
                    value={this.state.action}
                    onChange={this.handleTextChange}
                    disabled={true}
                  />
                </div>
              </form>
            </div>
          </div>
        )}
        <div className="col-md-8 mr-auto ml-auto">
          <br />
          <ul className="list-group list-group-flush">
            {this.state.actionsList.map(action => {
              return (
                <li key={shortid.generate()} className="list-group-item">
                  {action}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}
