import React from "react";
import questions from "./QuestoesCrencas";

export default function StartQuizOptions({ crencasState, setCrencasState }) {
  return (
    <>
      <div className="row">
        <div className="col-md-12 text-center">
          <h3>Formulário de Crenças</h3>
        </div>
        <div className="col-md-9 ml-auto mr-auto text-center">
          {!crencasState.done ? (
            <button
              onClick={() =>
                setCrencasState(c => ({
                  ...c,
                  doing: true,
                  done: false,
                  current: 0,
                  questions,
                  total: questions.length,
                  ambiente: 0,
                  causaEfeito: 0,
                  capacidade: 0,
                  valor: 0,
                  identidade: 0,
                  pertencimento: 0,
                  espiritualidade: 0,
                  name: null
                }))
              }
              className="btn btn-primary"
            >
              <strong style={{ textTransform: "none", fontSize: "14px" }}>
                Iniciar o teste
              </strong>
            </button>
          ) : (
            <a
              href="https://app.ondazul.online/comportamento"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary"
            >
              <strong style={{ textTransform: "none", fontSize: "14px" }}>
                Concluir e ver resultado
              </strong>
            </a>
          )}
          {crencasState.done ? (
            <>
              {crencasState.coupleDone ? (
                <a
                  href="https://app.ondazul.online/comportamento"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="btn btn-primary"
                >
                  <strong style={{ textTransform: "none", fontSize: "14px" }}>
                    Concluir e ver resultado do cônjuge
                  </strong>
                </a>
              ) : (
                <button
                  onClick={() =>
                    setCrencasState(c => ({
                      ...c,
                      done: false,
                      doing: true,
                      current: 0,
                      questions,
                      total: questions.length,
                      ambiente: 0,
                      causaEfeito: 0,
                      capacidade: 0,
                      valor: 0,
                      identidade: 0,
                      pertencimento: 0,
                      espiritualidade: 0,
                      name: null
                    }))
                  }
                  className="btn btn-primary"
                >
                  <strong style={{ textTransform: "none", fontSize: "14px" }}>
                    Iniciar teste do cônjuge
                  </strong>
                </button>
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
