import axios from "axios";
import { getToken } from "./auth";

const api = axios.create({
  baseURL:
    process.env.REACT_APP_API_URL || "https://ondazul-backend.herokuapp.com"
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  response => response,
  err => {
    if (err.response.status === 401) {
      localStorage.clear();
      alert("Seção expirada, refaça o Login");
      window.location.reload();
    }
    return Promise.reject(err);
  }
);

export default api;
