import React from "react";
import VideoItem from "./VideoItem";

const VideoList = ({ videos, play, lastVideoNum, isLoaded }) => {
  return videos.map(modulo => {
    return (
      <>
        <div key={modulo.idModulo} className="nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href={"#".concat(modulo.idModulo)}
              style={{ color: "#00B4DB", fontWeight: "bold" }}
            >
              <p style={{ textTransform: "none", whiteSpace: "normal" }}>
                {modulo.nomeModulo}
                <b className="caret" />
              </p>
            </a>
          </li>
          <div className="collapse" id={modulo.idModulo}>
            <ul className="nav">
              <VideoItem
                key={modulo.idModulo}
                modulo={modulo}
                play={play}
                isLoaded={isLoaded}
              />
            </ul>
          </div>
        </div>
      </>
    );
  });
};
export default VideoList;
