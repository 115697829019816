import React, { useState, useEffect } from "react";
import Quiz from "./Quiz";
import "./index.css";
import questoes from "./QuestoesMMD";
import api from "../../services/api";
import StartQuizOptions from "./StartQuizOptions";

export default function Crencas() {
  const [mmdState, setMmdState] = useState({
    answers: [],
    current: 0,
    questoes,
    total: questoes.length,
    pesos: {
      pesoEsperanca: 1.0582,
      pesoReatividade: 0.9661,
      pesoInseguranca: 1.0582,
      pesoCarencia: 1.0101,
      pesoInsatisfacao: 0.9259,
      pesoIdentificacao: 0.9876,
      pesoNegligencia: 0.9259,
      pesoImpulsividade: 1.0335,
      pesoOtimismo: 1.0582
    },
    done: false,
    coupleDone: false,
    doing: false,
    name: null
  });

  useEffect(() => {
    async function fetchMmd() {
      const { data: mmd } = await api.get("api/mmd");
      if (mmd) {
        setMmdState(mmdState => ({
          ...mmdState,
          answers: mmd,
          done: !!mmd.length,
          coupleDone: !!mmd[1]
        }));
      }
    }
    fetchMmd();
  }, []);

  return (
    <>
      {!mmdState.doing && (
        <StartQuizOptions mmdState={mmdState} setMmdState={setMmdState} />
      )}
      {mmdState.doing && <Quiz mmd={mmdState} setMmdState={setMmdState} />}
    </>
  );
}
