import React from "react";

const RessignificarCrencas = () => {
  return (
    <div className="row">
      <div className="col-md-12 text-center">
        <h3>Ressignificar Crenças</h3>
        <p>
          <a
            href="https://app.ondazul.online/Ressignificacao"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary text-center"
          >
            <strong style={{ textTransform: "none", fontSize: "14px" }}>
              Ir para Ondazul
            </strong>
          </a>
        </p>
      </div>
    </div>
  );
};

export default RessignificarCrencas;
