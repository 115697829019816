import React from "react";
import VideoList from "./VideoList";
import Progress from "react-progressbar";

const Sidebar = ({ videos, play, lastVideoNum, isLoaded }) => {
  const completed = parseInt((lastVideoNum / 53) * 100);
  return (
    <div className="sidebar" data-background-color="black" id="sidebarMenu">
      {/* <!-- User Settings --> */}
      <div className="logo">
        <a href="#" className="simple-text logo-mini">
          VA
        </a>
        <a href="#" className="simple-text logo-normal">
          Videoaulas
        </a>
      </div>

      <div className="sidebar-wrapper">
        <div className="text-center">
          <br />
          <a
            href="https://app.ondazul.online/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-info btn-lg"
          >
            <strong>Ir para Ondazul</strong>
          </a>
        </div>

        <div className="text-center">
          <br />
          <Progress completed={completed} />
          <p style={{ color: "white", marginTop: "5pt" }}>
            {completed}% concluído
          </p>
        </div>

        <VideoList
          videos={videos}
          play={play}
          lastVideoNum={lastVideoNum}
          isLoaded={isLoaded}
        />
      </div>
    </div>
  );
};

export default Sidebar;
