import React from "react";
import ReactPlayer from "react-player/lib/players/Vimeo";
import ActivityRenderer from "./ActivityRenderer";
import ComoRessignificar from "../activities/ComoRessignificar";

const VideoDetail = ({ video, sair, play, changeIsLoaded }) => {
  if (!video) {
    return <div>Loading...</div>;
  }
  if (video.title === "2.1 - Ressignificação de crenças") {
    video.activity = <ComoRessignificar play={play} />;
  }
  return (
    <div className="main-panel">
      {/* <!-- Navbar --> */}
      <nav
        className="navbar navbar-expand-lg navbar-transparent bg-dark"
        style={{ marginLeft: "10pt", marginRight: "10pt" }}
      >
        <button
          className="btn btn-info navbar-toggler"
          type="button"
          data-target="#sidebarMenu"
          aria-controls="sidebarMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="material-icons">menu</i>
        </button>
        <span className="navbar-text">
          <h3 id="videoTitle" style={{ marginLeft: "10pt" }}>
            {video.title}
          </h3>
        </span>
        <div className="navbar-form"></div>
        <ul className="navbar-nav ml-auto">
          <a href="#" style={{ color: "white" }} onClick={() => sair()}>
            <i className="material-icons">exit_to_app</i> Sair
          </a>
        </ul>
      </nav>
      {/* <!-- Video Content --> */}
      <div className="content" style={{ marginTop: "-20px" }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 embed-responsive embed-responsive-16by9">
              <ReactPlayer
                className="react-player"
                onReady={changeIsLoaded}
                url={video.url}
                width="100%"
                height="110%"
              />
            </div>
          </div>
          <div className="row">
            <ActivityRenderer activity={video.activity} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoDetail;
