import React from "react";
import api from "../services/api";
import { login } from "../services/auth";
import { Redirect, withRouter } from "react-router-dom";

class LoginPage extends React.Component {
  state = {
    email: "",
    senha: "",
    loading: false,
    error: ""
  };

  handleSignIn = async e => {
    e.preventDefault();
    this.setState({
      loading: true
    });
    const { email, senha } = this.state;
    if (!email || !senha) {
      this.setState({ error: "Preencha e-mail e senha para continuar" });
    } else {
      try {
        const response = await api.post("api/session", { email, senha });
        login(response.data.token);
        this.props.history.push("/home");
      } catch (err) {
        this.setState({
          error: "Houve um problema com o login, verifique suas credenciais."
        });
      }
    }
  };

  render() {
    return localStorage.getItem("@app-Token") ? (
      <Redirect to="/home" />
    ) : (
      <div className="wrapper wrapper-full-page">
        <div
          class="page-header login-page header-filter"
          filter-color="black"
          style={{
            backgroundImage: "url('../../assets/img/login.jpg')",
            backgroundSize: "cover",
            backgroundPosition: "top center"
          }}
        >
          <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top text-white">
            <div class="container">
              <div class="navbar-wrapper">
                <a class="navbar-brand" href="/">
                  REAL Cultura Financeira<div class="ripple-container"></div>
                </a>
              </div>
              <div class="justify-content-end">
                <ul class="navbar-nav">
                  <li class="nav-item ">
                    <a
                      class="nav-link"
                      href="https://app.ondazul.online/esqueci_minha_senha"
                    >
                      <i class="material-icons">lock</i>ESQUECI MINHA SENHA
                      <div class="ripple-container"></div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6 ml-auto mr-auto">
                <div className="card card-login">
                  <div className="card-header card-header-info text-center">
                    <h4 className="card-title">
                      <strong>PLATAFORMA DE VÍDEOS</strong>
                    </h4>
                  </div>
                  <form onSubmit={this.handleSignIn}>
                    <div class="card-body">
                      <p class="card-description text-center">
                        {this.state.error}
                      </p>
                      <span class="bmd-form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="material-icons">email</i>
                            </span>
                          </div>
                          <input
                            type="email"
                            className="form-control"
                            id="user"
                            aria-describedby="emailHelp"
                            placeholder="E-mail"
                            onChange={e =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </div>
                      </span>
                      <span class="bmd-form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">
                              <i class="material-icons">lock_outline</i>
                            </span>
                          </div>
                          <input
                            type="password"
                            className="form-control"
                            id="senha"
                            placeholder="Senha"
                            onChange={e =>
                              this.setState({ senha: e.target.value })
                            }
                          />
                        </div>
                      </span>
                    </div>
                    <br />
                    <div class="card-footer justify-content-center">
                      <button type="submit" className="btn btn-info btn-lg">
                        {this.state.loading ? (
                          <i className="fa fa-spinner fa-pulse" />
                        ) : (
                          <strong>Entrar</strong>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(LoginPage);
